import React from "react";

const ExamplePDFViewer = () => {
  return (
    <div style={{ padding: 20 }}>
      Welcome to our Privacy Policy -- <br />
      Your privacy is critically important to us.
      <br />
      <br />
      It is Coat Color's policy to respect your privacy regarding any
      information we may collect while operating our website. This Privacy
      Policy applies to bakia.co (hereinafter, "us", "we", or "bakia.co"). We
      respect your privacy and are committed to protecting personally
      identifiable information you may provide us through the Website. We have
      adopted this privacy policy ("Privacy Policy") to explain what information
      may be collected on our Website, how we use this information, and under
      what circumstances we may disclose the information to third parties. This
      Privacy Policy applies only to information we collect through the Website
      and does not apply to our collection of information from other sources.
      <br />
      This Privacy Policy, together with the Terms and conditions posted on our
      Website, set forth the general rules and policies governing your use of
      our Website. Depending on your activities when visiting our Website, you
      may be required to agree to additional terms and conditions.
      <br />
      <br />
      - Website Visitors
      <br/>
      Like most website operators, Coat Color collects
      non-personally-identifying information of the sort that web browsers and
      servers typically make available, such as the browser type, language
      preference, referring site, and the date and time of each visitor request.
      Coat Color's purpose in collecting non-personally identifying information
      is to better understand how Coat Color's visitors use its website. From
      time to time, Coat Color may release non-personally-identifying
      information in the aggregate, e.g., by publishing a report on trends in
      the usage of its website.
      <br />
      Coat Color also collects potentially personally-identifying information
      like Internet Protocol (IP) addresses for logged in users and for users
      leaving comments on http://bakia.co blog posts. Coat Color only discloses
      logged in user and commenter IP addresses under the same circumstances
      that it uses and discloses personally-identifying information as described
      below.
      <br />
      <br />
      - Gathering of Personally-Identifying Information
      <br/>
      Certain visitors to Coat
      Color's websites choose to interact with Coat Color in ways that require
      Coat Color to gather personally-identifying information. The amount and
      type of information that Coat Color gathers depends on the nature of the
      interaction. For example, we ask visitors who sign up for a blog at
      http://bakia.co to provide a username and email address.
      <br />
      <br />
      - Security
      <br/>
      The security of your Personal Information is important to us,
      but remember that no method of transmission over the Internet, or method
      of electronic storage is 100% secure. While we strive to use commercially
      acceptable means to protect your Personal Information, we cannot guarantee
      its absolute security.
      <br />
      <br />
      - Advertisements
      <br/>
      Ads appearing on our website may be delivered to users by
      advertising partners, who may set cookies. These cookies allow the ad
      server to recognize your computer each time they send you an online
      advertisement to compile information about you or others who use your
      computer. This information allows ad networks to, among other things,
      deliver targeted advertisements that they believe will be of most interest
      to you. This Privacy Policy covers the use of cookies by Coat Color and
      does not cover the use of cookies by any advertisers.
      <br />
      <br />
      - Links To External Sites
      <br/>
      Our Service may contain links to external
      sites that are not operated by us. If you click on a third party link, you
      will be directed to that third party's site. We strongly advise you to
      review the Privacy Policy and terms and conditions of every site you
      visit. We have no control over, and assume no responsibility for the
      content, privacy policies or practices of any third party sites, products
      or services.
      <br/>
      <br/>
      - Aggregated Statistics
      <br/> 
      Coat Color may collect statistics
      about the behavior of visitors to its website. Coat Color may display this
      information publicly or provide it to others. However, Coat Color does not
      disclose your personally-identifying information. 
      <br/>
      <br/>
      - Cookies
      <br/>
      To enrich and
      perfect your online experience, Coat Color uses "Cookies", similar
      technologies and services provided by others to display personalized
      content, appropriate advertising and store your preferences on your
      computer. A cookie is a string of information that a website stores on a
      visitor's computer, and that the visitor's browser provides to the website
      each time the visitor returns. Coat Color uses cookies to help Coat Color
      identify and track visitors, their usage of http://bakia.co, and their
      website access preferences. Coat Color visitors who do not wish to have
      cookies placed on their computers should set their browsers to refuse
      cookies before using Coat Color's websites, with the drawback that certain
      features of Coat Color's websites may not function properly without the
      aid of cookies.
      <br/>
      By continuing to navigate our website without changing
      your cookie settings, you hereby acknowledge and agree to Coat Color's use
      of cookies. 
      <br/>
      <br/>
      Privacy Policy Changes
      <br/>
      Although most changes are likely to be
      minor, Coat Color may change its Privacy Policy from time to time, and in
      Coat Color's sole discretion. Coat Color encourages visitors to frequently
      check this page for any changes to its Privacy Policy. Your continued use
      of this site after any change in this Privacy Policy will constitute your
      acceptance of such change.
      <br/>
      <br/> 
      - Contact Information If you have any questions
      about this Privacy Policy, please contact us via or phone.
    </div>
  );
};

export default ExamplePDFViewer;
