
import React from "react";
import Fab from "@material-ui/core/Fab";
import InstagramIcon from "@material-ui/icons/Instagram";
import logo from "./logo.png";
import "./App.css";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>En construcción...</p>
        <p>
          Síguenos en:{""}
          <a
            href="https://www.instagram.com/ledogco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Fab variant="extended" className="instagram socialButton">
              <InstagramIcon className="icon" /> Instagram
            </Fab>
          </a>
        </p>
      </header>
    </div>
  );
}

export default Home;
